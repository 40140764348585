// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// ** Axios Imports

import axios, { AxiosError } from 'axios';
import { deleteToast, errorToast, successToast } from '@universities/store';
import ICommunityQuestion from '../types/ICommunityQuestion';

const initState: ICommunityQuestion[] = [];

export const getCommunityQuestions = createAsyncThunk('commmunityQuestions/getCommunityQuestions', async (universityId: string) => {
  const response = await axios.get(`${process.env.REACT_APP_URL_ENV}/universities/${universityId}/community-questions`);
  return {
    data: response.data,
  };
});

export const createCommunityQuestion = async (
  universityId: string, communityQuestion: ICommunityQuestion,
) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_URL_ENV}/universities/${universityId}/community-question`, communityQuestion);
    successToast('Custom question successfully added!');
    return {
      data: response.data,
    };
  } catch (err) {
    if (err instanceof AxiosError) {
      errorToast(err?.response?.data?.message);
    }
    return Promise.reject(new Error('An unexpected error occurred'));
  }
};

export const updateCommunityQuestion = async (
  universityId: string, _id: string, communityQuestion: ICommunityQuestion,
) => {
  try {
    const response = await axios.put(`${process.env.REACT_APP_URL_ENV}/universities/${universityId}/community-question/${_id}`, communityQuestion);
    successToast('Custom question successfully updated!');
    return {
      data: response.data,
    };
  } catch (err) {
    if (err instanceof AxiosError) {
      errorToast(err?.response?.data?.message);
    }
    return Promise.reject(new Error('An unexpected error occurred'));
  }
};

export const deleteCommunityQuestion = async (universityId: string, _id?: string) => {
  try {
    const response = await axios.put(`${process.env.REACT_APP_URL_ENV}/universities/${universityId}/community-question/delete/${_id}`);
    deleteToast('Custom question successfuly deleted!');
    return {
      data: response.data,
    };
  } catch (err) {
    if (err instanceof AxiosError) {
      errorToast(err?.response?.data?.message);
    }
    return Promise.reject(new Error('An unexpected error occurred'));
  }
};

export const communityQuestionsSlice = createSlice({
  name: 'communityQuestions',
  initialState: {
    data: initState,
    currentQuestion: {} as ICommunityQuestion,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getCommunityQuestions.fulfilled, (state, action) => {
      state.data = action.payload.data;
    });
  },
});

export default communityQuestionsSlice.reducer;
