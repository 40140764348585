import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { errorToast, deleteToast } from '@universities/store';
import axios from 'axios';
import { FailedLogin, FailedLoginsParams } from '../types';

export const getFailedLogins = createAsyncThunk(
  'getFailedLogins',
  async (params: FailedLoginsParams) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_URL_ENV}/dashboard/login-attempts`,
        {
          params,
        },
      );
      return {
        list: response?.data?.docs || [],
        count: response?.data?.count || 0,
      };
    } catch (err) {
      errorToast();
      return Promise.reject(new Error('An unexpected error occurred'));
    }
  },
);

export const deleteFailedLogin = createAsyncThunk(
  'deleteFailedLogin',
  async (_id: string) => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_URL_ENV}/dashboard/login-attempts/${_id}`,
      );
      deleteToast('Successfuly deleted!');
      return {
        data: _id,
      };
    } catch (err) {
      errorToast();
      return Promise.reject(new Error('An unexpected error occurred'));
    }
  },
);

export const failedLogins = createSlice({
  name: 'failedLogins',
  initialState: {
    count: 0,
    list: [] as FailedLogin[],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getFailedLogins.fulfilled, (state, action) => {
      state.list = action.payload.list;
      state.count = action.payload.count;
    });
    builder.addCase(deleteFailedLogin.fulfilled, (state, action) => {
      state.list = state.list.filter(
        (loginAttempt) => loginAttempt._id !== action.payload.data,
      );
      state.count -= 1;
    });
  },
});

export default failedLogins.reducer;
