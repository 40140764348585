import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { successToast, errorToast } from '@universities/store';
import axios, { AxiosError } from 'axios';
import User, { Role } from '@users/types/User';
import {
  BotCreationPayload,
  BotEditPayload,
  IGoinBotConfig,
  UpdateCommunitiesConfig,
  UpdateConnectionConfig,
  UpdateMessagingRequest,
} from '../types';

const initState: User[] = [];

export const getAllGoinBots = createAsyncThunk('goin/getAllGoinBots', async () => {
  const response = await axios.get(`${process.env.REACT_APP_URL_ENV}/goin-bots`);
  return {
    data: response.data,
  };
});

export const getGoinBotConfigById = createAsyncThunk('goin/getGoinBotConfigById', async (botId: string) => {
  const response = await axios.get(`${process.env.REACT_APP_URL_ENV}/goin-bot-configs/${botId}`);
  return {
    data: response.data,
  };
});

export const updateConnectionsConfig = async (
  botId: string, req: UpdateConnectionConfig,
) => {
  try {
    const response = await axios.put(`${process.env.REACT_APP_URL_ENV}/goin-bots/${botId}/connections-config`, req);
    successToast('Connections config successfully updated!');
    return {
      data: response.data,
    };
  } catch (err) {
    if (err instanceof AxiosError) {
      errorToast(err?.response?.data?.message);
    }
    return Promise.reject(new Error('An unexpected error occurred'));
  }
};

export const updateCommunitiesConfig = async (
  botId: string, req: UpdateCommunitiesConfig,
) => {
  try {
    const response = await axios.put(`${process.env.REACT_APP_URL_ENV}/goin-bots/${botId}/universities-config`, req);
    successToast('Communities config successfully updated!');
    return {
      data: response.data,
    };
  } catch (err) {
    if (err instanceof AxiosError) {
      errorToast(err?.response?.data?.message);
    }
    return Promise.reject(new Error('An unexpected error occurred'));
  }
};

export const updateMessageConfig = async (
  botId: string, req: UpdateMessagingRequest,
) => {
  try {
    const response = await axios.put(`${process.env.REACT_APP_URL_ENV}/goin-bots/${botId}/messaging-config`, req);
    successToast('Messaging config successfully updated!');
    return {
      data: response.data,
    };
  } catch (err) {
    if (err instanceof AxiosError) {
      errorToast(err?.response?.data?.message);
    }
    return Promise.reject(new Error('An unexpected error occurred'));
  }
};

export const createGoinBot = createAsyncThunk(
  'goin/createGoinBot',
  async (values: BotCreationPayload) => {
    try {
      const formData = new FormData();
      Object.entries(values).forEach(([key, value]) => {
        if (value !== undefined && value !== null) {
          if (key === 'profilePicture' && value instanceof File) {
            formData.append(key, value);
          } else {
            formData.append(key, String(value));
          }
        }
      });

      const response = await axios.post(
        `${process.env.REACT_APP_URL_ENV}/goin-bots`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      );

      successToast('Successfully created!');
      return {
        data: response.data,
      };
    } catch (err) {
      errorToast();
      return Promise.reject(new Error('An unexpected error occurred'));
    }
  },
);

export const editGoinBot = createAsyncThunk(
  'goin/editGoinBot',
  async ({ values, botId }: { values: BotEditPayload; botId: string }) => {
    try {
      const formData = new FormData();
      Object.entries(values).forEach(([key, value]) => {
        if (value !== undefined && value !== null) {
          if (key === 'profilePicture' && value instanceof File) {
            formData.append(key, value);
          } else {
            formData.append(key, String(value));
          }
        }
      });

      const response = await axios.put(
        `${process.env.REACT_APP_URL_ENV}/goin-bots/${botId}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      );

      successToast('Successfully updated!');
      return {
        data: response.data,
      };
    } catch (err) {
      errorToast();
      return Promise.reject(new Error('An unexpected error occurred'));
    }
  },
);

export const updateGoinBotRoles = async (
  botId: string, roles: { universityId: string; role: Role }[],
) => {
  try {
    const response = await axios.put(`${process.env.REACT_APP_URL_ENV}/goin-bots/${botId}`, {roles});
    successToast('Successfully updated!');
    return {
      data: response.data,
    };
  } catch (err) {
    if (err instanceof AxiosError) {
      errorToast(err?.response?.data?.message);
    }
    return Promise.reject(new Error('An unexpected error occurred'));
  }
}; 

export const goinBotsSlice = createSlice({
  name: 'goinBots',
  initialState: {
    data: initState,
    currentBotConfig: {} as IGoinBotConfig,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllGoinBots.fulfilled, (state, action) => {
      state.data = action.payload.data;
    });
    builder.addCase(getGoinBotConfigById.fulfilled, (state, action) => {
      state.currentBotConfig = action.payload.data;
    });
  },
});

export default goinBotsSlice.reducer;
