export enum FeedbackTone {
  POSITIVE = 'Positive',
  NEGATIVE = 'Negative',
  NEUTRAL = 'Neutral',
  CRITICAL = 'Critical',
};

export interface DeleteFeedbackRequest {
  uniId: string;
  userId: string;
};

interface FeedbackRequest {
  uniId:string;
  userId: string;
  content?: string;
  highlight?: boolean;
  tone?: FeedbackTone;
};

export default FeedbackRequest;
