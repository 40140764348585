import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axios, { AxiosError } from 'axios';
import toast from 'react-hot-toast';
import { successToast, deleteToast, errorToast } from '@universities/store';
import {
  ArrivalGuideContent,
  AGContentPostRequest,
  ArrivalGuideLink,
  ModulesOrderRequest,
  EventRequest,
  ArrivalGuideEvent,
} from '../types';

export const addArrivalGuideModule = async (
  _id: string,
  module: AGContentPostRequest | ArrivalGuideLink | ArrivalGuideEvent,
) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_URL_ENV}/universities/${_id}/arrival`,
      module,
    );
    successToast('Module successfully added!');
    return {
      data: response.data,
    };
  } catch (err) {
    if (err instanceof AxiosError) {
      toast.error(err?.response?.data.message, { position: 'top-center' });
    } else {
      errorToast();
    }
    return Promise.reject(new Error('An unexpected error occurred'));
  }
};

export const getArrivalGuide = createAsyncThunk(
  '/arrival',
  async (_id: string) => {
    const response = await axios.get(
      `${process.env.REACT_APP_URL_ENV}/universities/${_id}/arrival`,
    );
    return {
      data: response.data,
    };
  },
);

export const updateArrivalGuideModule = async (
  universityId: string,
  arrivalId: string,
  module: ArrivalGuideContent | ArrivalGuideLink | ArrivalGuideEvent,
) => {
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_URL_ENV}/universities/${universityId}/arrival/${arrivalId}`,
      module,
    );
    successToast('Module successfully updated!');
    return {
      data: response.data,
    };
  } catch (err) {
    if (err instanceof AxiosError) {
      toast.error(err?.response?.data.message, { position: 'top-center' });
    } else {
      errorToast();
    }
    return Promise.reject(new Error('An unexpected error occurred'));
  }
};

export const deleteModule = async (universityId: string, arrivalId: string) => {
  try {
    const response = await axios.delete(
      `${process.env.REACT_APP_URL_ENV}/universities/${universityId}/arrival/${arrivalId}`,
    );
    deleteToast('Module successfuly deleted!');
    return {
      data: response.data,
    };
  } catch (err) {
    errorToast();
    return Promise.reject(new Error('An unexpected error occurred'));
  }
};

export const updateModulesOrder = async (
  universityId: string,
  arrivalIds: ModulesOrderRequest,
) => {
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_URL_ENV}/universities/${universityId}/arrival`,
      {
        arrivalIds,
      },
    );
    successToast('Modules order updated!');
    return {
      data: response.data,
    };
  } catch (err) {
    errorToast();
    return Promise.reject(new Error('An unexpected error occurred'));
  }
};

export const addEvent = async (
  universityId: string,
  arrivalId: string,
  event: EventRequest,
) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_URL_ENV}/universities/${universityId}/arrival/${arrivalId}/event`,
      event,
    );
    successToast('Event added!');
    return {
      data: response.data,
    };
  } catch (err) {
    errorToast();
    return Promise.reject(new Error('An unexpected error occurred'));
  }
};

export const updateEvent = async (
  universityId: string,
  arrivalId: string,
  eventId: string,
  event: EventRequest,
) => {
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_URL_ENV}/universities/${universityId}/arrival/${arrivalId}/event/${eventId}`,
      event,
    );
    successToast('Event updated!');
    return {
      data: response.data,
    };
  } catch (err) {
    errorToast();
    return Promise.reject(new Error('An unexpected error occurred'));
  }
};

export const getEvents = createAsyncThunk(
  '/events',
  async ({
    universityId,
    arrivalId,
  }: {
    universityId: string;
    arrivalId: string;
  }) => {
    const response = await axios.get(
      `${process.env.REACT_APP_URL_ENV}/universities/${universityId}/arrival/${arrivalId}/event`,
    );
    return {
      data: response.data,
    };
  },
);

export const deleteEvent = async (
  universityId: string,
  arrivalId: string,
  eventId: string,
) => {
  try {
    const response = await axios.delete(
      `${process.env.REACT_APP_URL_ENV}/universities/${universityId}/arrival/${arrivalId}/event/${eventId}`,
    );
    deleteToast('Event successfuly deleted!');
    return {
      data: response.data,
    };
  } catch (err) {
    errorToast();
    return Promise.reject(new Error('An unexpected error occurred'));
  }
};

export const uploadEventImage = async (universityId: string, file: File) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_URL_ENV}/universities/${universityId}/events/file-upload`,
      {
        file,
      },
      { headers: { 'Content-Type': 'multipart/form-data' } },
    );
    return {
      data: response.data,
    };
  } catch (err) {
    errorToast();
    return Promise.reject(new Error('An unexpected error occurred'));
  }
};

export const arrivalGuideSlice = createSlice({
  name: 'arrivalGuide',
  initialState: {
    list: [],
    listChanged: false,
    edit: false,
    previewData: {},
    events: [],
    eventModule: null,
  },
  reducers: {
    setEditMode: (state, action: PayloadAction<boolean>) => {
      state.edit = action.payload;
    },
    setPreviewData: (state, action) => {
      state.previewData = { ...state.previewData, ...action.payload };
    },
    setArrivalGuideList: (state, action) => {
      state.list = action.payload;
    },
    setListChanged: (state, action) => {
      state.listChanged = action.payload;
    },
    resetPreviewData: (state) => {
      state.previewData = {};
    },
    setEvents: (state, action) => {
      state.events = action.payload;
    },
    setEvent: (state, action) => {
      state.eventModule = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getArrivalGuide.fulfilled, (state, action) => {
      state.list = action.payload.data;
    });
    builder.addCase(getEvents.fulfilled, (state, action) => {
      state.events = action.payload.data;
    });
  },
});

export const {
  setEditMode,
  setPreviewData,
  setArrivalGuideList,
  setListChanged,
  resetPreviewData,
  setEvents,
  setEvent,
} = arrivalGuideSlice.actions;

export default arrivalGuideSlice.reducer;
