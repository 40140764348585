// ** Redux Imports
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

// ** Axios Imports
import axios, { AxiosError } from 'axios';
import { errorToast, successToast } from '@universities/store';
import UpserResourceReq from '../types/UpsertResourceReq';
import GetResourceReq from '../types/GetResourceReq';
import { IResourceMaterial } from '../types/ResourceMaterialDefaultState';

export const upsertResources = async (req:UpserResourceReq) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_URL_ENV}/universities/resource-material`, req);
    successToast('Resource material successfully saved!');
    return {
      data: response.data,
    };
  } catch (err) {
    if (err instanceof AxiosError) {
      return errorToast(err?.response?.data?.message);
    }
    return Promise.reject(new Error('An unexpected error occurred'));
  }
};

export const getResourceMaterial = createAsyncThunk('goin/getResources', async (params: GetResourceReq) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_URL_ENV}/universities/resource-material`, { params });
    return {
      data: response.data,
    };
  } catch (err) {
    if (err instanceof AxiosError) {
      errorToast(err?.response?.data?.message);
    }
    return Promise.reject(new Error('An unexpected error occurred'));
  }
});

export const resourceMaterialSlice = createSlice({
  name: 'resources',
  initialState: {
    data: [],
    currentResource: {} as IResourceMaterial,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getResourceMaterial.fulfilled, (state, action) => {
      state.currentResource = action.payload.data;
    });
  },
});

export default resourceMaterialSlice.reducer;
