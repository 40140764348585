import { X } from 'tabler-icons-react';
import toast from 'react-hot-toast';
import Avatar from '../@core/components/avatar/index';

const ToastWrapper = (
  title:string | undefined, subtitle:React.ReactNode, icon:React.ReactNode, color:string,
) => (
  toast((t: { id: string | undefined; }) => (
    <div className="d-flex">
      <div className="me-1">
        <Avatar size="sm" color={color} icon={icon} />
      </div>
      <div className="d-flex flex-column">
        <div className="d-flex justify-content-between">
          {title && <h6>{title}</h6>}
          <X size={12} className="cursor-pointer" data-testid="dismiss" onClick={() => toast.dismiss(t.id)} />
        </div>
        <span>
          {subtitle}
        </span>
      </div>
    </div>
  )));

export default ToastWrapper;
